import { RouteConfig } from 'vue-router'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import modals from './modals'

mdl.register(modals)

class FlyExtension extends RewardsExtension {
  setRoutes() {
    return [
      {
        path: '/fly',
        name: 'fly',
        component: () => import('./fly.vue'),
        redirect: { name: 'fly-partners-list' },
        children: [
          {
            path: 'transfer',
            name: 'fly-points-transfer',
            props: true,
            component: () => import('./points-transfer/points-transfer.vue'),
            meta: {
              title: 'Fly Points Transfer',
              page: 'airline-partners',
            },
          },
          {
            path: 'transfer/successful/:number',
            name: 'fly-points-transfer-successful',
            props: true,
            component: () =>
              import('./points-transfer/points-transfer-successful.vue'),
            meta: {
              title: 'Fly Points Transfer Successful',
            },
          },
          {
            path: 'business',
            name: 'fly-business',
            component: () => import('./business/business.vue'),
            meta: {
              title: 'Fly Business',
              page: 'airline-partners',
            },
          },
          {
            path: 'partners',
            component: () => import('./partners/partners.vue'),
            children: [
              {
                path: 'list/:state?',
                name: 'fly-partners-list',
                component: () => import('./partners/list/partners-list.vue'),
                meta: {
                  title: 'Fly Partners',
                  page: 'airline-partners',
                },
              },
              {
                path: 'new',
                name: 'fly-partner-new',
                component: () => import('./partners/add/partner-add-new.vue'),
                meta: {
                  title: 'Fly New Partner',
                  page: 'airline-partners',
                },
              },
            ],
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default FlyExtension
